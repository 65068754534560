import i18n from '@/utils/common/i18n';
import { Domain, RoleCode } from '@/types/common/enums';
import { rejectedReasons } from '@/constants/fi/constants';
import { FiTaskDefinitionKeys } from '@/types/fi/enums';
import { LvTaskDefinitionKeys } from '@/types/lv/enums';
import { getSelectedDomain } from '@/utils/common/getSelectedDomain';
import { EeTaskDefinitionKeys } from '@/types/ee/enums';
import { LtTaskDefinitionKeys } from '@/types/lt/enums';

const applicationStatus = {
  [Domain.FI]: [
    {
      statuses: rejectedReasons,
      label: i18n.tc('applicationStatus.rejected'),
    },
    {
      statuses: [
        'ACTIVE_CONTRACT',
      ],
      label: i18n.tc('applicationStatus.complete'),
    },
    {
      statuses: [
        'INCOMPLETE_FOR_DECIDING',
        'DECIDING',
        'NOT_CLOSED',
      ],
      label: i18n.tc('applicationStatus.incomplete'),
    },
    {
      statuses: [
        'RATE_TOO_HIGH',
        'COMMISSION_TOO_HIGH',
        'MONTHLY_PAYMENT_TOO_HIGH',
        'OFFERED_SUM_TOO_SMALL',
        'LOAN_PERIOD_TOO_LONG',
        'WAITED_TOO_LONG_FOR_OFFER',
        'CANNOT_PROVIDE_REQUIRED_DOCUMENTS',
        'CANNOT_SIGN_CONTRACT',
        'BETTER_OFFER_ELSEWHERE',
        'RECEIVED_LOAN_FROM_OTHER_SOURCE',
        'CANNOT_PROVIDE_COBORROWER',
        'APPLICANT_IS_UNREACHABLE',
        'FINANCIAL_SITUATION_IMPROVED',
        'NO_NEED',
        'NO_ANSWER',
        'PARTNER_LOAN_TAKEN',
        'NO_CONSENT',
        'NOT_INTERESTED_IN_REFINANCING',
        'REFUSED_DEBT_REPAYMENT',
        'DOUBLE_APPLICATION',
        'APPLICATION_EXPIRED',
        'OFFER_EXPIRED',
      ],
      label: i18n.tc('applicationStatus.annulled'),
    },
  ],
  [Domain.LT]: [
    {
      statuses: [
        'REJECTED_AUTO',
        'SUSPECTED_FRAUD',
        'HIGH_RISK',
        'OTHER_ISSUES',
      ],
      label: i18n.tc('applicationStatus.rejected'),
    },
    {
      statuses: [
        'ACTIVE_CONTRACT',
      ],
      label: i18n.tc('applicationStatus.complete'),
    },
    {
      statuses: [
        'INCOMPLETE_FOR_DECIDING',
        'DECIDING',
        'NOT_CLOSED',
      ],
      label: i18n.tc('applicationStatus.incomplete'),
    },
    {
      statuses: [
        'PARTNER_LOAN_TAKEN',
        'BETTER_OFFER_ELSEWHERE',
        'COMMISSION_TOO_HIGH',
        'REFUSED_DEBT_REPAYMENT',
        'CANNOT_PROVIDE_REQUIRED_DOCUMENTS',
        'FINANCIAL_SITUATION_IMPROVED',
        'NO_ANSWER',
        'NO_CONSENT',
        'NO_NEED',
        'APPLICANT_IS_UNREACHABLE',
        'RECEIVED_LOAN_FROM_OTHER_SOURCE',
        'MONTHLY_PAYMENT_TOO_HIGH',
        'LOAN_PERIOD_TOO_LONG',
        'RATE_TOO_HIGH',
        'CANNOT_SIGN_CONTRACT',
        'OFFERED_SUM_TOO_SMALL',
        'CANNOT_PROVIDE_COBORROWER',
        'WAITED_TOO_LONG_FOR_OFFER',
        'APPLICATION_EXPIRED',
        'DOUBLE_APPLICATION',
        'NOT_INTERESTED_IN_REFINANCING',
      ],
      label: i18n.tc('applicationStatus.annulled'),
    },
  ],
  [Domain.EE]: [
    {
      statuses: [
        'REJECTED_AUTO',
        'SUSPECTED_FRAUD',
        'EMPLOYMENT_RELATED_ISSUES',
        'ENTREPRENEUR_RELATED_ISSUES',
        'AML',
        'HIGH_RISK',
        'OTHER_ISSUES',
        'SANCTIONED_PERSON',
        'PEP',
        'GAMBLING',
        'MANY_FAST_LOANS',
        'COOPERATION_HISTORY',
        'MANY_LIABILITIES',
      ],
      label: i18n.tc('applicationStatus.rejected'),
    },
    {
      statuses: [
        'ACTIVE_CONTRACT',
      ],
      label: i18n.tc('applicationStatus.complete'),
    },
    {
      statuses: [
        'INCOMPLETE_FOR_DECIDING',
        'DECIDING',
        'NOT_CLOSED',
      ],
      label: i18n.tc('applicationStatus.incomplete'),
    },
    {
      statuses: [
        'RATE_TOO_HIGH',
        'COMMISSION_TOO_HIGH',
        'MONTHLY_PAYMENT_TOO_HIGH',
        'OFFERED_SUM_TOO_SMALL',
        'LOAN_PERIOD_TOO_LONG',
        'WAITED_TOO_LONG_FOR_OFFER',
        'CANNOT_PROVIDE_REQUIRED_DOCUMENTS',
        'CANNOT_SIGN_CONTRACT',
        'BETTER_OFFER_ELSEWHERE',
        'RECEIVED_LOAN_FROM_OTHER_SOURCE',
        'CANNOT_PROVIDE_COBORROWER',
        'APPLICANT_IS_UNREACHABLE',
        'FINANCIAL_SITUATION_IMPROVED',
        'NO_NEED',
        'NO_ANSWER',
        'PARTNER_LOAN_TAKEN',
        'NO_CONSENT',
        'NOT_INTERESTED_IN_REFINANCING',
        'REFUSED_DEBT_REPAYMENT',
        'DOUBLE_APPLICATION',
        'APPLICATION_EXPIRED',
        'OFFER_EXPIRED',
        'CANNOT_FINISH_FLOW',
        'LOAN_NOT_NEEDED_COVID19',
        'MIN_REQUIREMENTS_NOT_MET',
      ],
      label: i18n.tc('applicationStatus.annulled'),
    },
  ],
  [Domain.LV]: [
    {
      statuses: [
        'REJECTED_AUTO',
        'SUSPECTED_FRAUD',
        'EMPLOYMENT_RELATED_ISSUES',
        'ENTREPRENEUR_RELATED_ISSUES',
        'AML',
        'HIGH_RISK',
        'OTHER_ISSUES',
        'SANCTIONED_PERSON',
        'PEP',
        'GAMBLING',
        'MANY_FAST_LOANS',
        'COOPERATION_HISTORY',
        'MANY_LIABILITIES',
        'BUY_PROPERTY',
        'ACTIVE_DEBTS',
        'MIN_REQUIREMENTS_NOT_MET',
      ],
      label: i18n.tc('applicationStatus.rejected'),
    },
    {
      statuses: [
        'ACTIVE_CONTRACT',
      ],
      label: i18n.tc('applicationStatus.complete'),
    },
    {
      statuses: [
        'INCOMPLETE_FOR_DECIDING',
        'DECIDING',
        'NOT_CLOSED',
      ],
      label: i18n.tc('applicationStatus.incomplete'),
    },
    {
      statuses: [
        'RATE_TOO_HIGH',
        'COMMISSION_TOO_HIGH',
        'MONTHLY_PAYMENT_TOO_HIGH',
        'OFFERED_SUM_TOO_SMALL',
        'LOAN_PERIOD_TOO_LONG',
        'WAITED_TOO_LONG_FOR_OFFER',
        'CANNOT_PROVIDE_REQUIRED_DOCUMENTS',
        'CANNOT_SIGN_CONTRACT',
        'BETTER_OFFER_ELSEWHERE',
        'RECEIVED_LOAN_FROM_OTHER_SOURCE',
        'CANNOT_PROVIDE_COBORROWER',
        'APPLICANT_IS_UNREACHABLE',
        'FINANCIAL_SITUATION_IMPROVED',
        'NO_NEED',
        'NO_ANSWER',
        'PARTNER_LOAN_TAKEN',
        'NO_CONSENT',
        'NOT_INTERESTED_IN_REFINANCING',
        'REFUSED_DEBT_REPAYMENT',
        'DOUBLE_APPLICATION',
        'APPLICATION_EXPIRED',
        'OFFER_EXPIRED',
        'CANNOT_FINISH_FLOW',
        'MIN_REQUIREMENTS_NOT_MET',
      ],
      label: i18n.tc('applicationStatus.annulled'),
    },
  ],
};

const taskDefinitionKeyStatus = {
  [Domain.FI]: {
    [FiTaskDefinitionKeys.CREDIT_APPROVED]: i18n.tc('applicationStatus.approved'),
    [FiTaskDefinitionKeys.CREDIT_DECLINED]: i18n.tc('applicationStatus.rejected'),
    [FiTaskDefinitionKeys.CREDIT_DECLINED_BRING_CO_BORROWER]: i18n.tc('applicationStatus.rejected'),
    [FiTaskDefinitionKeys.WAITING_DECISION]: i18n.tc('applicationStatus.waitingForManualDecision'),
    [FiTaskDefinitionKeys.APPLICANT_SIGNING]: i18n.tc('applicationStatus.contractApplicantSigning'),
    [FiTaskDefinitionKeys.CO_APPLICANT_SIGNING]: i18n.tc('applicationStatus.contractCoApplicantSigning'),
    [FiTaskDefinitionKeys.PARTNER_SIGNING]: i18n.tc('applicationStatus.contractPartnerSigning'),
    [FiTaskDefinitionKeys.CONTRACT_IN_REVIEW]: i18n.tc('applicationStatus.contractInReview'),
    [FiTaskDefinitionKeys.APPROVED_WITH_CONDITIONS]: i18n.tc('applicationStatus.approvedWithConditions'),
    [FiTaskDefinitionKeys.WAIT_COLLATERAL_DATA_FIX]: i18n.tc('applicationStatus.waitingForManualProcessToBeFinished'),
  },
  [Domain.LV]: {
    [LvTaskDefinitionKeys.READ_UNDECIDED_DECISION]: i18n.tc('applicationStatus.analysis'),
    [LvTaskDefinitionKeys.ADDITIONAL_DATA_SUBMITTED_THANK_YOU_PAGE_BEFORE_PROCESSING_UNDERWRITER_MANUAL_RULES]: i18n.tc('applicationStatus.analysis'),
    [LvTaskDefinitionKeys.ADDITIONAL_DATA_SUBMITTED_THANK_YOU_PAGE_BEFORE_IS_REFINANCING_ENABLED_CHECK]: i18n.tc('applicationStatus.analysis'),
    [LvTaskDefinitionKeys.ADDITIONAL_DATA_SUBMITTED_THANK_YOU_PAGE_BEFORE_REFINANCING_SUBFLOW]: i18n.tc('applicationStatus.analysis'),
    [LvTaskDefinitionKeys.ADDITIONAL_DATA_SUBMITTED_THANK_YOU_PAGE]: i18n.tc('applicationStatus.analysis'),
    [LvTaskDefinitionKeys.SHORT_APPLICATION_SUBMITTED_THANK_YOU_PAGE]: i18n.tc('applicationStatus.analysis'),
    [LvTaskDefinitionKeys.DUPLICATE_APPLICATION_THANK_YOU_PAGE]: i18n.tc('applicationStatus.annulled'),
    [LvTaskDefinitionKeys.REVIEW_APPROVED]: i18n.tc('applicationStatus.approved'),
    [LvTaskDefinitionKeys.REVIEW_APPROVED_UPSALE]: i18n.tc('applicationStatus.approved'),
    [LvTaskDefinitionKeys.REVIEW_APPROVED_FINAL]: i18n.tc('applicationStatus.approved'),
    [LvTaskDefinitionKeys.REVIEW_APPROVED_UPSALE_FINAL]: i18n.tc('applicationStatus.approved'),
    [LvTaskDefinitionKeys.BANK_ACCOUNT_STATEMENTS_BEING_PROCESSED_THANK_YOU_PAGE]: i18n.tc('applicationStatus.basAnalysis'),
    [LvTaskDefinitionKeys.BANK_ACCOUNT_STATEMENT_PROCESSING_TAKES_TIME_THANK_YOU_PAGE]: i18n.tc('applicationStatus.basAnalysis'),
    [LvTaskDefinitionKeys.ANALYZE_BANK_ACCOUNT_STATEMENT_DOCUMENTS]: i18n.tc('applicationStatus.basAnalysis'),
    [LvTaskDefinitionKeys.ANALYZE_BANK_ACCOUNT_STATEMENT_DOCUMENTS_THANK_YOU_PAGE]: i18n.tc('applicationStatus.basAnalysis'),
    [LvTaskDefinitionKeys.POST_OFFICE_CHOSEN_FOR_CONTRACT_SIGNING_THANK_YOU_PAGE]: i18n.tc('applicationStatus.contract'),
    [LvTaskDefinitionKeys.VIEW_AND_SIGN_CONTRACT_CONTENT_LOADER]: i18n.tc('applicationStatus.contractReady'),
    [LvTaskDefinitionKeys.VIEW_CONTRACT_CONDITIONS]: i18n.tc('applicationStatus.contractSigning'),
    [LvTaskDefinitionKeys.PARTNER_CONTRACT_IS_SIGNED_THANK_YOU_PAGE]: i18n.tc('applicationStatus.documentsAreUploaded'),
    [LvTaskDefinitionKeys.CHECK_SIGNED_DOCUMENTS]: i18n.tc('applicationStatus.documentsAreUploaded'),
    [LvTaskDefinitionKeys.READ_NEGATIVE_DECISION]: i18n.tc('applicationStatus.rejected'),
    [LvTaskDefinitionKeys.READ_NEGATIVE_DECISION_INITIAL]: i18n.tc('applicationStatus.rejected'),
    [LvTaskDefinitionKeys.READ_NEGATIVE_DECISION_REFINANCING]: i18n.tc('applicationStatus.rejected'),
    [LvTaskDefinitionKeys.READ_NEGATIVE_DECISION_FINAL]: i18n.tc('applicationStatus.rejected'),
    [LvTaskDefinitionKeys.SHOW_CONTRACT_SIGNING_NEGATIVE_PAGE]: i18n.tc('applicationStatus.rejected'),
    [LvTaskDefinitionKeys.SHOW_NEGATIVE_THANK_YOU_PAGE]: i18n.tc('applicationStatus.rejected'),
    [LvTaskDefinitionKeys.CONTRACT_SIGNED_IN_POST_OFFICE_END_EVENT]: i18n.tc('applicationStatus.signed'),
    [LvTaskDefinitionKeys.SUBMIT_BACKGROUND_INFO]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LvTaskDefinitionKeys.REVIEW_APPROVED_ADDITIONAL_CHECK]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LvTaskDefinitionKeys.SUBMIT_BANK_DETAILS_AND_IDENTIFICATION_DOCUMENT_INFO]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LvTaskDefinitionKeys.SUBMIT_ADDRESS]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LvTaskDefinitionKeys.PARTNER_SUBMIT_ADDITIONAL_DATA]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LvTaskDefinitionKeys.SHOW_LOAN_AND_CONTACT_DATA_INCOMES_AND_LIABILITIES_PAGE]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LvTaskDefinitionKeys.SUBMIT_SIGNING_DETAILS]: i18n.tc('applicationStatus.submitAdditionalDataForContract'),
    [LvTaskDefinitionKeys.SHOULD_SEND_BANK_ACCOUNT_STATEMENT_PROCESSED_EMAIL_EXCLUSIVE_GATEWAY]: i18n.tc('applicationStatus.waitingForBas'),
    [LvTaskDefinitionKeys.UPLOAD_BANK_ACCOUNT_STATEMENT_DOCUMENTS]: i18n.tc('applicationStatus.waitingForBas'),
    [LvTaskDefinitionKeys.BANK_ACCOUNT_STATEMENT_UPLOAD_SKIPPED_THANK_YOU_PAGE]: i18n.tc('applicationStatus.waitingForBas'),
    [LvTaskDefinitionKeys.BANK_ACCOUNT_STATEMENT_UPLOAD_RETRIES_EXCEEDED_THANK_YOU_PAGE]: i18n.tc('applicationStatus.waitingForBas'),
    [LvTaskDefinitionKeys.GENERATE_CONTRACT]: i18n.tc('applicationStatus.contract'),
    [LvTaskDefinitionKeys.VERIFY_THAT_CONTRACT_IS_SIGNED]: i18n.tc('applicationStatus.contractReady'),
    [LvTaskDefinitionKeys.CONTRACT_IS_SIGNED_THANK_YOU_PAGE]: i18n.tc('applicationStatus.contractReady'),
    [LvTaskDefinitionKeys.START_PARTNER_SIGNING]: i18n.tc('applicationStatus.contract'),
    [LvTaskDefinitionKeys.SUBMIT_EMPLOYER_INFO]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LvTaskDefinitionKeys.CHOOSE_SIGNING_METHOD]: i18n.tc('applicationStatus.chooseSigningMethod'),
    [LvTaskDefinitionKeys.PROVIDE_BAS_VIA_OPEN_BANKING]: i18n.tc('applicationStatus.provideBas'),
    [LvTaskDefinitionKeys.READ_UNDECIDED_HOUSING_LOAN_DECISION]: i18n.tc('applicationStatus.basAnalysis'),
    [LvTaskDefinitionKeys.VALIDATE_UPLOADED_STATEMENTS_AFTER_VALIDATION_RESULT]: i18n.tc('applicationStatus.validateUploadedStatementsAfterValidationResult'),
  },
  [Domain.LT]: {
    [LtTaskDefinitionKeys.SUBMIT_BANK_DETAILS]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LtTaskDefinitionKeys.SUBMIT_ADDRESS]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LtTaskDefinitionKeys.ADDITIONAL_DATA_SUBMITTED_THANK_YOU_PAGE]: i18n.tc('applicationStatus.analysis'),
    [LtTaskDefinitionKeys.SHOW_CONTRACT_SIGNING_NEGATIVE_PAGE]: i18n.tc('applicationStatus.rejected'),
    [LtTaskDefinitionKeys.VIEW_AND_SIGN_CONTRACT]: i18n.tc('applicationStatus.contractSigning'),
    [LtTaskDefinitionKeys.VERIFY_THAT_CONTRACT_IS_SIGNED]: i18n.tc('applicationStatus.verifyThatContractIsSigned'),
    [LtTaskDefinitionKeys.GENERATE_CONTRACT]: i18n.tc('applicationStatus.approved'),
    [LtTaskDefinitionKeys.WHATS_NEXT_PAGE_BANK_LINK]: i18n.tc('applicationStatus.whatsNextPageBankLink'),
    [LtTaskDefinitionKeys.REMIND_CUSTOMER_TO_SIGN_CONSENT]: i18n.tc('applicationStatus.contractSigning'),
    [LtTaskDefinitionKeys.IDENTIFY_ONLINE]: i18n.tc('applicationStatus.identifyOnline'),
    [LtTaskDefinitionKeys.READ_NEGATIVE_DECISION]: i18n.tc('applicationStatus.rejected'),
    [LtTaskDefinitionKeys.REVIEW_APPROVED]: i18n.tc('applicationStatus.approved'),
    [LtTaskDefinitionKeys.REVIEW_APPROVED_UPSALE]: i18n.tc('applicationStatus.approved'),
    [LtTaskDefinitionKeys.REVIEW_CONDITIONAL_NEGATIVE]: i18n.tc('applicationStatus.rejected'),
    [LtTaskDefinitionKeys.READ_CONDITIONAL_POSITIVE_OFFER]: i18n.tc('applicationStatus.approved'),
    [LtTaskDefinitionKeys.READ_UNDECIDED_DECISION]: i18n.tc('applicationStatus.signConsentAtPaypost'),
    [LtTaskDefinitionKeys.REVIEW_APPROVED_ADDITIONAL_CHECK]: i18n.tc('applicationStatus.approved'),
    [LtTaskDefinitionKeys.SUBMIT_SPOUSE_INCOMES_AND_LIABILITIES]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LtTaskDefinitionKeys.SHOW_SPOUSE_INCOMES_AND_LIABILITIES_FIELDS]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LtTaskDefinitionKeys.REVIEW_APPROVED_FINAL]: i18n.tc('applicationStatus.approved'),
    [LtTaskDefinitionKeys.REVIEW_APPROVED_UPSALE_FINAL]: i18n.tc('applicationStatus.approved'),
    [LtTaskDefinitionKeys.REVIEW_APPROVED_LEASING]: i18n.tc('applicationStatus.approved'),
    [LtTaskDefinitionKeys.CHECK_SIGNED_CONTRACT]: i18n.tc('applicationStatus.contract'),
    [LtTaskDefinitionKeys.VIEW_AND_SIGN_CONTRACT_CONTENT_LOADER]: i18n.tc('applicationStatus.contract'),
    [LtTaskDefinitionKeys.READ_CONDITIONAL_REFINANCE_POSITIVE_OFFER]: i18n.tc('applicationStatus.approved'),
    [LtTaskDefinitionKeys.UPLOAD_DOCUMENTS]: i18n.tc('applicationStatus.documentsUpload'),
    [LtTaskDefinitionKeys.UPLOAD_LEASING_DOCUMENTS]: i18n.tc('applicationStatus.documentsUpload'),
    [LtTaskDefinitionKeys.SHOW_SPOUSE_CONTACT_OR_FULL_DATA_PAGE]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LtTaskDefinitionKeys.SHOW_LOAN_AND_CONTACT_DATA_INCOMES_AND_LIABILITIES_PAGE]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LtTaskDefinitionKeys.SUBMIT_INCOMES_AND_LIABILITIES]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LtTaskDefinitionKeys.SPOUSE_INCOMES_AND_LIABILITIES]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LtTaskDefinitionKeys.SHOW_PERSONAL_DATA_PAGE_SPA]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LtTaskDefinitionKeys.UPLOAD_REFINANCING_DOCUMENTS]: i18n.tc('applicationStatus.documentsUpload'),
    [LtTaskDefinitionKeys.SUBMIT_SPOUSE_PERSONAL_DATA]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LtTaskDefinitionKeys.SUBMIT_SPOUSE_ADDRESS]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LtTaskDefinitionKeys.SUBMIT_ONLY_SPOUSE_ADDRESS]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LtTaskDefinitionKeys.SUBMIT_SPOUSE_FINANCE_DATA]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LtTaskDefinitionKeys.UPLOAD_SPOUSE_DOCUMENTS]: i18n.tc('applicationStatus.documentsUpload'),
    [LtTaskDefinitionKeys.UPLOAD_EMPLOYMENT_DOCUMENTS]: i18n.tc('applicationStatus.documentsUpload'),
    [LtTaskDefinitionKeys.ASK_CUSTOMER_TO_INVOLVE_SPOUSE]: i18n.tc('applicationStatus.incomplete'),
    [LtTaskDefinitionKeys.SUBMIT_BANK_AND_ADDRESS_INCLUDING_SPOUSE_ADDRESS]: i18n.tc('applicationStatus.submitAdditionalData'),
    [LtTaskDefinitionKeys.IDENTIFY_AT_PARTNER_STORE]: i18n.tc('applicationStatus.identifyOnline'),
    [LtTaskDefinitionKeys.IDENTIFY_AT_PARTNER_STORE_SPOUSE]: i18n.tc('applicationStatus.identifyOnline'),
    [LtTaskDefinitionKeys.PROVIDE_BAS_VIA_OPEN_BANKING]: i18n.tc('applicationStatus.provideBas'),
  },
  [Domain.EE]: {
    [EeTaskDefinitionKeys.VIEW_PARTNER_UNDECIDED_THANK_YOU_PAGE]: i18n.tc('applicationStatus.analysis'),
    [EeTaskDefinitionKeys.VIEW_APPROVED_ADDITIONAL_CHECKS_THANK_YOU_PAGE]: i18n.tc('applicationStatus.analysis'),
    [EeTaskDefinitionKeys.SUBMIT_INCOME_AND_LIABILITIES]: i18n.tc('applicationStatus.submitAdditionalData'),
    [EeTaskDefinitionKeys.CONFIRM_CUSTOMERS_NAME]: i18n.tc('applicationStatus.identifyCustomer'),
    [EeTaskDefinitionKeys.VIEW_OFFER]: i18n.tc('applicationStatus.approved'),
    [EeTaskDefinitionKeys.SUBMIT_CONSENTS_AND_ADDITIONAL_DATA]: i18n.tc('applicationStatus.submitAdditionalData'),
    [EeTaskDefinitionKeys.POSITIVE_DECISION]: i18n.tc('applicationStatus.approved'),
    [EeTaskDefinitionKeys.INFORM_PARTNER_ABOUT_NEED_OF_MANUAL_ANALYSIS]: i18n.tc('applicationStatus.approvedWithConditions'),
    [EeTaskDefinitionKeys.INFORM_PARTNER_ABOUT_NEED_OF_MANUAL_ANALYSIS_FINAL]: i18n.tc('applicationStatus.approvedWithConditions'),
    [EeTaskDefinitionKeys.VIEW_OFFER_MANUAL_CHECK_NEEDED]: i18n.tc('applicationStatus.approvedWithConditions'),
    [EeTaskDefinitionKeys.VIEW_NEGATIVE_DECISION_THANK_YOU_PAGE]: i18n.tc('applicationStatus.rejected'),
    [EeTaskDefinitionKeys.VIEW_OFFER_BAS_NEEDED]: i18n.tc('applicationStatus.approvedSubmitAdditionalData'),
    [EeTaskDefinitionKeys.SUBMIT_ADDITIONAL_DATA]: i18n.tc('applicationStatus.approvedSubmitAdditionalData'),
    [EeTaskDefinitionKeys.RETRY_UPDATE_APPLICATION_DATA_FROM_POPULATION_REGISTRY]: i18n.tc('applicationStatus.submitData'),
    [EeTaskDefinitionKeys.VIEW_NEGATIVE_THANK_YOU_PAGE]: i18n.tc('applicationStatus.rejected'),
    [EeTaskDefinitionKeys.VIEW_HP_THANK_YOU_PAGE]: i18n.tc('applicationStatus.signed'),
    [EeTaskDefinitionKeys.VIEW_AND_SIGN_HP_CONTRACT]: i18n.tc('applicationStatus.contractSigning'),
    [EeTaskDefinitionKeys.VIEW_DOCUMENTS_UPLOADED_THANK_YOU_PAGE]: i18n.tc('applicationStatus.documentsAreUploaded'),
    [EeTaskDefinitionKeys.UPLOAD_DOCUMENTS]: i18n.tc('applicationStatus.waitingForBas'),
    [EeTaskDefinitionKeys.PROVIDE_BAS_VIA_OPEN_BANKING]: i18n.tc('applicationStatus.waitingForBas'),
    [EeTaskDefinitionKeys.ACTIVATE_CONTRACT]: i18n.tc('applicationStatus.contractSigned'),
    [EeTaskDefinitionKeys.IDENTIFY_CUSTOMER]: i18n.tc('applicationStatus.identifyCustomer'),
    [EeTaskDefinitionKeys.SUBMIT_TAX_REGISTRY_CONSENT]: i18n.tc('applicationStatus.submitTaxRegistryConsent'),
    [EeTaskDefinitionKeys.VALIDATE_UPLOADED_DOCUMENTS]: i18n.tc('applicationStatus.validateUploadedDocuments'),
    [EeTaskDefinitionKeys.ANALYSE]: i18n.tc('applicationStatus.analyse'),
  },
};

export function hasSupportedTask (application) {
  const domain = getSelectedDomain();
  const userData = localStorage.user ? JSON.parse(localStorage.user) : {};
  const isPartnerAudit = localStorage.user ? JSON.parse(localStorage.user).roleCode === RoleCode.PARTNER_AUDIT : false;

  if (window.config.DOMAIN === Domain.INTERNAL && !userData.partnerId) {
    return false;
  }

  if (isPartnerAudit) {
    return false;
  }

  if (domain === Domain.FI && application.status.taskDefinitionKey) {
    return taskDefinitionKeyStatus[Domain.FI][application.status.taskDefinitionKey];
  }

  if (domain === Domain.LV && application.status.taskDefinitionKey) {
    if ([
      LvTaskDefinitionKeys.VALIDATE_UPLOADED_STATEMENTS_AFTER_VALIDATION_RESULT,
      LvTaskDefinitionKeys.ANALYZE_BANK_ACCOUNT_STATEMENT_DOCUMENTS,
    ].includes(application.status.taskDefinitionKey)) {
      return false;
    }

    return taskDefinitionKeyStatus[Domain.LV][application.status.taskDefinitionKey];
  }

  if (domain === Domain.EE && application.status.taskDefinitionKey) {
    if ([
      EeTaskDefinitionKeys.ACTIVATE_CONTRACT,
      EeTaskDefinitionKeys.UPLOAD_DOCUMENTS,
      EeTaskDefinitionKeys.VALIDATE_UPLOADED_DOCUMENTS,
      EeTaskDefinitionKeys.ANALYSE,
    ].includes(application.status.taskDefinitionKey)) {
      return false;
    }

    return taskDefinitionKeyStatus[Domain.EE][application.status.taskDefinitionKey];
  }

  if (domain === Domain.LT && application.status.taskDefinitionKey) {
    return taskDefinitionKeyStatus[Domain.LT][application.status.taskDefinitionKey];
  }

  return false;
}

export function getApplicationStatus (applicationRow) {
  if (!applicationRow.status?.taskDefinitionKey && !applicationRow.status?.reasons) {
    return applicationRow.status;
  }

  const domain = getSelectedDomain();

  if (taskDefinitionKeyStatus[domain][applicationRow.status?.taskDefinitionKey]) {
    return taskDefinitionKeyStatus[domain][applicationRow.status?.taskDefinitionKey];
  }

  if (applicationRow.status?.reasons[0]) {
    const activeStatus = applicationStatus[domain].find(status => status.statuses.indexOf(applicationRow.status.reasons[0]) > -1);

    if (!activeStatus) {
      return applicationRow.status?.status
        ? i18n.tc(`applicationStatus.${applicationRow.status?.status.toLowerCase()}`)
        : i18n.tc('Unmapped');
    }

    return activeStatus.label || i18n.tc('applicationStatus.unmapped');
  }

  return applicationRow.status?.status || i18n.tc('Unmapped');
}

